<template>
  <div class="add-service-provider">
    <Modal1
      ref="addServiceProviderModal1"
      :loading="addingServiceProvider"
      @submit="submit"
    >
      <b-row>

        <!-- code -->
        <b-col md="6">
          <prism
            language="javascript"
            class="rounded "
          >
            {{ validation.terrif }}
          </prism>
        </b-col>
      </b-row>
      <template #header>
        Service Provider
      </template>
      <template #body>
        <validation-observer ref="addServiceProviderForm">
          <b-form class="add-service-provider-form">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="payload.provider_name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Country"
                  label-for="country"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="country"
                    rules="required"
                  >
                    <b-form-select
                      id="country"
                      v-model="payload.country"
                      placeholder="Country"
                      :state="errors.length > 0 ? false:null"
                      :options="countries"
                      :class="[{'color-grey':!payload.country}]"
                      style="padding-left: 0.438rem !important;"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="tariff"
                  rules="required|positive"
                >
                  <b-form-group
                    label="Tariff"
                    label-for="terrif"
                  >
                    <b-input-group
                      :class="{'is-invalid': errors[0]}"
                      class="input-group-merge"
                      prepend="$"
                    >
                      <b-form-input
                        id="Terrif"
                        v-model="payload.terrif"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Tarrif"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </Modal1>
  </div>
</template>
<script>
import {
  BFormGroup, BCol, BRow, BForm,
  BFormInput, BFormSelect, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import countries from '../../../countires.json'
import Modal1 from '@/common/components/modals/Modal1.vue'
import { positive, showToast } from '@/common/global/functions'
import { addServiceProvider } from '@/api/admin/simmiIOTEngine/serviceProviders'

export default {
  name: 'AddServiceProvider',
  components: {
    Modal1,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BForm,
    BFormInput,
    Prism,
    BFormSelect,
    BInputGroup,
  },
  data() {
    return {
      required,
      addingServiceProvider: false,
      countries: [{
        text: 'Country',
        value: null,
      }, ...countries],
      payload: {
        provider_name: null,
        country: null,
        terrif: null,
      },
      validation: {
        terrif: positive,
      },
    }
  },
  methods: {
    show() {
      this.$refs.addServiceProviderModal1.show()
      this.payload = {
        provider_name: null,
        country: null,
        terrif: null,
      }
    },
    hide() {
      this.$refs.addServiceProviderModal1.hide()
    },
    async submit() {
      this.$refs.addServiceProviderForm.validate().then(async success => {
        if (success) {
          this.addingServiceProvider = true
          try {
            this.payload.terrif = Number(this.payload.terrif)
            const { data } = await addServiceProvider(this.payload)
            if (data.success) {
              showToast('Service Provider', 'New service provider added successfully!', 'success')
              this.hide()
              this.$emit('getServiceProviders')
            } else if (data.message) {
              showToast('Service Provider', data.message, 'danger')
            }
          } catch (e) {
            showToast('Service Provider', 'Error adding service provider!', 'danger')
          } finally {
            this.addingServiceProvider = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
.add-service-provider-form{
  select{
    option:first-child{
      color: #c2c2ca !important;
    }
  }
}
</style>
