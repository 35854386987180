<template>
  <div class="service-providers">
    <b-overlay :show="removingServiceProvider">
      <Teleport to="#header-buttons">
        <div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            style="top : 0; right: 0;"
            @click="addServiceProvider"
          >
            <feather-icon
              icon="PlusCircleIcon"
              size="15"
            />
            Add Service Provider
          </b-button>
        </div>
      </Teleport>
      <b-card
        no-body
      >
        <b-skeleton-table
          v-if="gettingServiceProvidersData"
          :rows="paginationObject.limit"
          :columns="3"
          :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
        />
        <div
          v-else-if="paginatedServiceProviders.length === 0"
          class="text-center mt-5 font-large-1 text-black-50 mb-5"
        >No Service Provider Found</div>
        <b-table
          v-else
          ref="mediaTable"
          :items="paginatedServiceProviders"
          :per-page="paginationObject.limit"
          responsive
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          class="position-relative"
        >
          <template #cell(Name)="data">
            <div class="provider-name">
              {{ data.item.provider_name }}
            </div>
          </template>
          <template #cell(Country)="data">
            <div>{{ data.item.country }}</div>
          </template>
          <template #cell(Tariff)="data">
            <div>$ {{ data.item.terrif }}</div>
          </template>
          <template #cell(Actions)="data">
            <div class="text-center">
              <feather-icon
                class="cursor-pointer"
                icon="Trash2Icon"
                size="18"
                @click="removeServiceProvider(data.item.id)"
              />
            </div>
          </template>
        </b-table>
      </b-card>
      <div class="mt-0">
        <div class="text-nowrap">
          <!-- pagination -->
          <CustomPagination
            v-if="paginatedServiceProviders.length"
            :total="paginationObject.total"
            :per-page="paginationObject.limit"
            :page="paginationObject.offset"
            @perPageChanged="perPageChanged"
            @pageChanged="pageChanged"
          />
        </div>
      </div>
      <AddServiceProvider
        ref="addServiceProvider"
        @getServiceProviders="getAllServiceProviders"
      />
    </b-overlay>
  </div>
</template>
<script>
import {
  BTable, BButton, BCard, BSkeletonTable, BOverlay,
} from 'bootstrap-vue'
import CustomPagination from '@/common/components/CustomPagination.vue'
import { confirmDeletion, paginateArray, showToast } from '@/common/global/functions'
import { deleteServiceProvider, getAllServiceProviders } from '@/api/admin/simmiIOTEngine/serviceProviders'
import AddServiceProvider from '@/components/serviceProviders/AddServiceProvider.vue'

export default {
  name: 'Index',
  components: {
    AddServiceProvider,
    CustomPagination,
    BTable,
    BButton,
    BCard,
    BSkeletonTable,
    BOverlay,
  },
  data() {
    return {
      allServiceProvides: [],
      paginatedServiceProviders: [],
      gettingServiceProvidersData: false,
      tableColumns: [{ key: 'Name' }, { key: 'Country' }, { key: 'Tariff' }, {
        key: 'Actions',
        style: 'float: right',
        thClass: 'pull-center text-center',
      }],
      removingServiceProvider: false,
      paginationObject: {
        offset: 1,
        limit: 5,
        total: 0,
      },
    }
  },
  mounted() {
    this.getAllServiceProviders()
  },
  methods: {
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.paginateArray()
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.paginateArray()
    },
    async getAllServiceProviders() {
      this.gettingServiceProvidersData = true
      try {
        const { data: { success, response } } = await getAllServiceProviders()
        if (success) {
          this.allServiceProvides = response
          this.paginationObject.total = this.allServiceProvides.length
          this.paginateArray()
        } else {
          throw new Error('Error getting service providers')
        }
      } catch (e) {
        console.error(e)
        showToast('Service Providers', 'Error getting service providers!', 'danger')
      } finally {
        this.gettingServiceProvidersData = false
      }
    },
    paginateArray() {
      this.paginatedServiceProviders = paginateArray(this.allServiceProvides, this.paginationObject.limit, this.paginationObject.offset)
      if (this.paginatedServiceProviders.length === 0 && this.paginationObject.offset !== 1) {
        this.paginationObject.offset = 1
        this.paginateArray()
      }
    },
    addServiceProvider() {
      this.$refs.addServiceProvider.show()
    },
    removeServiceProvider(id) {
      confirmDeletion().then(async result => {
        if (result.value) {
          try {
            this.removingServiceProvider = true
            const { data } = await deleteServiceProvider(id)
            if (data.success) {
              showToast('Service Provider', 'Service provider has been removed successfully!', 'success')
            } else {
              showToast('Service Provider', data.message, 'danger')
            }
          } catch (e) {
            showToast('Service Provider', 'Error removing service provider!', 'danger')
          } finally {
            this.removingServiceProvider = false
            this.getAllServiceProviders()
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import "src/@core/scss/base/bootstrap-extended";
.service-providers{
  .provider-name{
    width: 100px;
  }
  .actions{
    width: 20px;
  }
  .cursor-pointer{
    &:hover{
      color: $primary;
    }
  }
}
</style>
