import simmiIOT from '@/auth/simmiIOT'

const SERVICE_PROVIDER = 'service-provider'

const getAllServiceProviders = () => simmiIOT.get(`${SERVICE_PROVIDER}`)

const addServiceProvider = payload => simmiIOT.post(`${SERVICE_PROVIDER}`, payload)

const deleteServiceProvider = id => simmiIOT.delete(`${SERVICE_PROVIDER}/delete/${id}`)

// eslint-disable-next-line import/prefer-default-export
export { getAllServiceProviders, addServiceProvider, deleteServiceProvider }
